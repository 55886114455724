// src/hooks/useTokenMonitor.js
import { useEffect, useState } from 'react';
import { checkToken } from '../utils/tokenUtils';

const tokenName = 'jwtToken'; // Replace with your actual token name

const useTokenMonitor = () => {
	const [tokenChecked, setTokenChecked] = useState(false);

	useEffect(() => {
		const handleStorageChange = () => {
			if (checkToken(tokenName)) {
				setTokenChecked(true);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		// Initial check
		handleStorageChange();

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return tokenChecked;
};

export default useTokenMonitor;
