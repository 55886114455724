import { getLangCode } from '../../helpers/localStorageConfig.js';

var stringHTML = null;
var styleYPx = null;
var HideOnMobile = "0";
var myLanguage = getLangCode;
var TSR = null;
var JR = null;
var ShopVoteJSONReviews = "null";
var my_div = null,
    newDiv = null,
    panel_div = null,
    MAX_CACHE_SECONDS = 1800;
null == sessionStorage.getItem("SVBadgeVisibility") && sessionStorage.setItem("SVBadgeVisibility", 1);

function css(e) {
    var c = document.createElement("link");
    c.type = "text/css";
    c.rel = "stylesheet";
    c.href = "https://widgets.shopvote.de/css/badge-" + e + ".css";
    document.getElementsByTagName("head")[0].appendChild(c);
}

function openPanel() {
    document.getElementById("sv-panel-box").style.display = "block";
}

function closePanel() {
    document.getElementById("sv-panel-box").style.display = "none";
}

function closeMobile() {
    document.getElementById("sv-rbadge-rb1fxb").style.display = "none";
    sessionStorage.setItem("SVBadgeVisibility", 0);
    return !1
}

function deleteOldCacheData(e) {
    for (var c = 0; c < sessionStorage.length; c++) null !== sessionStorage.key(c).match("SVCache") && null == sessionStorage.key(c).match("SVCacheExpiration_" + e) && null == sessionStorage.key(c).match("SVCacheData_" + e) && sessionStorage.removeItem(sessionStorage.key(c))
}

function isCacheExpired(e) {
    deleteOldCacheData(e);
    e = sessionStorage.getItem("SVCacheExpiration_" + e);
    var c = Math.round((new Date).getTime() / 1E3);
    null == e && (e = 0);
    return c > e ? 1 : 0
}

function setCacheData(e, c) {
    sessionStorage.setItem(e, c)
}

function getCacheData(e) {
    return sessionStorage.getItem(e)
}

export function createVBadge(e, c, x, h, k, r, t, y) {
    function A(l, d, a) {
        var b = eval("(" + l + ")");
        if (2 == c) var f = b.ratingvalue / 5 * 106;
        3 == c && (f = b.ratingvalue / 5 * 85);
        l = document.createElement("div");
        l.id = "sv-panel-box";
        l.className = "sv-panel";
        stringHTML = '<div class="sv-panel-head"><div class="sv-head-text">' + b.ratingcount + " " + b.panelText.kundenbewertung + '<br><span className="sv-head-stars">' + b.panelText.last12month + '</span></div></div><div class="sv-panel-content">';
        for (var g = 1; 7 >= g; g++) 1 == b.criteriamatrix[g] && (stringHTML = stringHTML +
            '<div class="sv-panel-row"><div class="sv-row-criteria"> <p className="sv-row-crname">' + b.criterianames[g] + '</p></div><div class="sv-row-cstars" title="' + b.criteriavalues[g] + "/5 " + b.panelText.sterne + '">' + b.starshtml[g] + "</div></div>");
        stringHTML = 0 < b.ratingcount ? stringHTML + '<div class="sv-panel-lastvote"><a href="https://www.shopvote.de/verifizierung-von-bewertungen" target="_blank" title="Hier klicken, um mehr \u00fcber die Echtheit der Bewertungen zu erfahren.">' + b.panelText.info_echtheit + " &#9654;</a></div></div>" :
            stringHTML + "</div>";
        stringHTML = stringHTML + '<div class="sv-panel-footer"><div class="sv-footer-left">SHOPVOTE&reg;</div><div class="sv-footer-right"><a href="https://www.shopvote.de/' + b.reviewpage + '" target="_blank">' + b.titleword + " &#9654;</a></div></div>";
        l.innerHTML = stringHTML;
        g = document.createElement("div");
        g.id = "sv-vbadge-box";
        g.className = "sv-vbadge-vb" + c + "fx";
        "right" == r && (g.style.right = d + "px");
        "left" == r && (g.style.left = d + "px");
        if ("bottom" == t) {
            g.style.bottom = a + "px";
            styleYPx = 15 + parseInt(a);
            l.style.bottom = styleYPx + "px";
        }
        if ("top" == t) {
            g.style.top = a + "px";
            styleYPx = parseInt(a) - 45;
            l.style.top = styleYPx + "px";
        }
        // "bottom" == t && (g.style.bottom = a + "px", styleYPx = 15 + parseInt(a), l.style.bottom =
        //     styleYPx + "px");
        // "top" == t && (g.style.top = a + "px", styleYPx = parseInt(a) - 45, l.style.top = styleYPx + "px");
        g.style.background = "url('https://widgets.shopvote.de/img/badge-" + c + "-" + b.sealcolor + ".png') no-repeat scroll 0 0 rgba(0, 0, 0, 0)";
        g.addEventListener("click", openPanel, !1);
        d = document.createElement("div");
        d.className = "sv-vbadge-vb" + c + "fx-ratingstars";
        d.style.width = f + "px";
        f = document.createElement("span");
        f.id = "sv-vbadge-vb" + c + "fx-stars";
        document.createElement("div").className = "sv-vbadge-vb" + c + "fx-count";
        a =
            document.createElement("div");
        a.className = "sv-vbadge-vb" + c + "fx-word";
        if ("2" == c) {
            a.style.fontSize = b.font_size + "px";
            a.style.marginTop = "6px";
            a.style.marginBottom = b.mb_correct + "px";
            if (1 > b.ratingcount) {
                a.style.marginTop = "6px";
                a.style.color = "#FFFFFF";
                a.style.lineHeight = "14px";
            }
            if (1 < b.ratingcount && "grey" == b.sealcolor) {
                a.style.marginTop = "11px";
                a.style.color = "#FFFFFF";
            }
        }
        if ("3" === c) {
            d.style.fontSize = a.font_size + "px";
            d.style.marginTop = "6px";
            d.style.marginBottom = a.mb_correct + "px";

            if (a.ratingcount < 1) {
                d.style.marginTop = "8px";
                d.style.color = "#FFFFFF";
                d.style.lineHeight = "14px";
            }

            if (a.ratingcount > 1 && a.sealcolor === "grey") {
                d.style.marginTop = "11px";
                d.style.color = "#FFFFFF";
            }
        }
        // "2" == c && (a.style.fontSize = b.font_size + "px", a.style.marginTop = "6px", a.style.marginBottom = b.mb_correct + "px", 1 > b.ratingcount && (a.style.marginTop = "6px", a.style.color = "#FFFFFF", a.style.lineHeight = "14px"), 1 < b.ratingcount && "grey" == b.sealcolor && (a.style.marginTop = "11px", a.style.color = "#FFFFFF"));
        // "3" == c && (a.style.fontSize = b.font_size + "px", a.style.marginTop = "6px", a.style.marginBottom = b.mb_correct + "px", 1 > b.ratingcount && (a.style.marginTop = "8px",
        //     a.style.color = "#FFFFFF", a.style.lineHeight = "14px"), 1 < b.ratingcount && "grey" == b.sealcolor && (a.style.marginTop = "11px", a.style.color = "#FFFFFF"));
        a.innerHTML = 1 > b.ratingcount ? '<span style="line-height: 14px;">' + b.ratingword + "</span>" : "<span>" + b.ratingword + "</span>";
        var v = document.createElement("div");
        v.className = "sv-vbadge-vb" + c + "fx-html";
        v.innerHTML = b.repHTML;
        var q = document.createElement("div");
        q.className = "sv-rbadge-rb1fxb";
        q.id = "sv-rbadge-rb1fxb";
        q.style.opacity = "0.8";
        var u = document.createElement("a");
        u.href = "https://www.shopvote.de/" + b.reviewpage;
        u.target = "_blank";
        u.title = "Bewertungen bei ShopVote ansehen";
        var n = document.createElement("div");
        n.className = "sv-rbadge-rb1fxb-logo";
        var p = document.createElement("img");
        p.className = "sv-rbadge-rb1fxb-logoimg";
        p.src = "https://img.shopvote.de/profile-seal-160-" + b.sealcolor + ".png";
        n.appendChild(p);
        p = document.createElement("div");
        p.className = "sv-rbadge-rb1fxb-wrapper";
        p.id = "sv-rbadge-rb1fxb-wrapper";
        var z = document.createElement("div");
        z.className = "sv-rbadge-rb1fxb-close";
        // z.innerHTML = '<span onClick="closeMobile()" style="color:#000000; font-weight: 600;"> &#215; </span>';
        z.innerHTML = '<span onClick="closeMobile()" style="color:#000000; font-weight: 400; font-size: 22px"> &#215; </span>';
        z.addEventListener("click", closeMobile, !1);
        document.createElement("div").className = "sv-rbadge-rb1fxb-logo";
        var B = document.createElement("div");
        B.className = "sv-rbadge-rb1fxb-data";
        B.innerHTML = b.repHTMLbottom;
        q.appendChild(p);
        p.appendChild(z);
        p.appendChild(u);
        u.appendChild(n);
        p.appendChild(B);
        g.appendChild(d);
        d.appendChild(f);
        g.appendChild(a);
        1 <= b.ratingcount && g.appendChild(v);
        my_div = document.body.lastChild;
        if ("0" == HideOnMobile) {
            document.body.insertBefore(g, my_div);
            if (1 == b.responsive && 1 == sessionStorage.getItem("SVBadgeVisibility")) {
                document.body.insertBefore(q, my_div);
            }
        }
        if (1 == b.SDAutoCode && 1 == b.activeStars) {
            b = '{\t"@context":"https://schema.org",  "@type":"Organization",  "@id":"' + b.ratingurl + '#organization",  "name":"' + b.ratingname + '",  "url":"' + b.ratingurl + '",  "aggregateRating": {  "@type":"AggregateRating",  "worstRating":"1",  "bestRating":"5",  "ratingValue":"' + b.ratingvalue + '",  "reviewCount":"' + b.ratingcount + '",  "sameAs":"https://www.shopvote.de/' + b.reviewpage + '" }}';
            TSR = document.head || document.getElementsByTagName("head")[0];
            JR = ShopVoteJSONReviews = document.createElement("script");
            JR.setAttribute("id", "ShopVoteJSONLd");
            JR.type = "application/ld+json";
            JR.appendChild(document.createTextNode(b));
            TSR.appendChild(JR);
        }
        // "0" == HideOnMobile && (document.body.insertBefore(g,
        //     my_div), 1 == b.responsive && 1 == sessionStorage.getItem("SVBadgeVisibility") && document.body.insertBefore(q, my_div));
        // 1 == b.SDAutoCode && 1 == b.activeStars && (b = '{\t"@context":"https://schema.org",  "@type":"Organization",  "@id":"' + b.ratingurl + '#organization",  "name":"' + b.ratingname + '",  "url":"' + b.ratingurl + '",  "aggregateRating": {  "@type":"AggregateRating",  "worstRating":"1",  "bestRating":"5",  "ratingValue":"' + b.ratingvalue + '",  "reviewCount":"' + b.ratingcount + '",  "sameAs":"https://www.shopvote.de/' +
        //     b.reviewpage + '" }}', TSR = document.head || document.getElementsByTagName("head")[0], JR = ShopVoteJSONReviews = document.createElement("script"), JR.setAttribute("id", "ShopVoteJSONLd"), JR.type = "application/ld+json", JR.appendChild(document.createTextNode(b)), TSR.appendChild(JR));
        panel_div = document.body.lastChild;
        "0" == HideOnMobile && document.body.insertBefore(l, panel_div);
        document.getElementById("sv-panel-box").addEventListener("mouseleave", closePanel, !1)
    }
    css(c);
    myLanguage = "undefined" == typeof myLanguage ? "de" :
        myLanguage.toLowerCase();
    x = "https://widgets.shopvote.de/votebadge.php?shopid=" + e + "&type=" + c + "&src=" + x + "&lang=" + myLanguage;
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    "undefined" === typeof r && (r = "right");
    "undefined" === typeof t && (t = "bottom");
    if ("undefined" === typeof k && "undefined" !== typeof h) {
        k = h;
        h = "2";
    }
    // "undefined" === typeof k && "undefined" !== typeof h && (k = h, h = "2");
    "undefined" === typeof h && (h = "0");
    "undefined" === typeof k && (k = "5");
    2 > h && (h = "0");
    5 > k && (k = "5");
    "undefined" === typeof y && (HideOnMobile = "0");
    isNaN(y) || (HideOnMobile =
        "1");
    "1" == HideOnMobile && w >= y && (HideOnMobile = "0");
    if (1 == isCacheExpired(e)) {
        var m = new XMLHttpRequest;
        m.onreadystatechange = function () {
            if (4 === m.readyState && 200 === m.status) {
                setCacheData("SVCacheExpiration_" + e, Math.round((new Date).getTime() / 1E3) + MAX_CACHE_SECONDS);
                setCacheData("SVCacheData_" + e, m.responseText);
                A(m.responseText, h, k);
            }
            // 4 === m.readyState && 200 === m.status && (setCacheData("SVCacheExpiration_" + e, Math.round((new Date).getTime() / 1E3) + MAX_CACHE_SECONDS), setCacheData("SVCacheData_" + e, m.responseText), A(m.responseText, h, k))
        };
        m.open("GET", x);
        m.send()
    } else A(getCacheData("SVCacheData_" + e), h, k)
}

// function createRBadge(e, c, x, h, k, r, t, y) {
//     function A(m, l, d) {
//         var a = eval("(" + m + ")");
//         if (2 == c) var b = a.ratingvalue / 5 * 106;
//         3 == c && (b = a.ratingvalue / 5 * 85);
//         m = document.createElement("div");
//         m.id = "sv-panel-box";
//         m.className = "sv-panel";
//         stringHTML = '<div class="sv-panel-head"><div class="sv-head-text">' + a.ratingcount + " " + a.panelText.kundenbewertung + '<br><span className="sv-head-stars">' + a.panelText.last12month + '</span></div></div><div class="sv-panel-content">';
//         for (var f = 1; 7 >= f; f++) 1 == a.criteriamatrix[f] && (stringHTML = stringHTML +
//             '<div class="sv-panel-row"><div class="sv-row-criteria"> <p className="sv-row-crname">' + a.criterianames[f] + '</p></div><div class="sv-row-cstars" title="' + a.criteriavalues[f] + "/5 " + a.panelText.sterne + '">' + a.starshtml[f] + "</div></div>");
//         stringHTML = 0 < a.ratingcount ? stringHTML + '<div class="sv-panel-lastvote">' + a.panelText.lastReviewDate + ": " + a.lastvote + "</div></div>" : stringHTML + "</div>";
//         stringHTML = stringHTML + '<div class="sv-panel-footer"><div class="sv-footer-left">SHOPVOTE&reg;</div><div class="sv-footer-right"><a href="https://www.shopvote.de/' +
//             a.reviewpage + '" target="_blank">' + a.titleword + " &#9654;</a></div></div>";
//         m.innerHTML = stringHTML;
//         f = document.createElement("div");
//         f.id = "sv-vbadge-box";
//         f.className = "sv-vbadge-vb" + c + "fx";
//         "right" == r && (f.style.right = l + "px");
//         "left" == r && (f.style.left = l + "px");
//         if ("bottom" == t) {
//             f.style.bottom = d + "px";
//             styleYPx = 15 + parseInt(d);
//             m.style.bottom = styleYPx + "px";
//         }
//         if ("top" == t) {
//             f.style.top = d + "px";
//             styleYPx = parseInt(d) - 45;
//             m.style.top = styleYPx + "px";
//         }
//         // "bottom" == t && (f.style.bottom = d + "px", styleYPx = 15 + parseInt(d), m.style.bottom = styleYPx + "px");
//         // "top" == t && (f.style.top = d + "px", styleYPx = parseInt(d) - 45, m.style.top = styleYPx + "px");
//         f.style.background = "url('https://widgets.shopvote.de/img/badge-" + c + "-" + a.sealcolor +
//             ".png') no-repeat scroll 0 0 rgba(0, 0, 0, 0)";
//         f.addEventListener("click", openPanel, !1);
//         l = document.createElement("div");
//         l.className = "sv-vbadge-vb" + c + "fx-ratingstars";
//         l.style.width = b + "px";
//         b = document.createElement("span");
//         b.id = "sv-vbadge-vb" + c + "fx-stars";
//         document.createElement("div").className = "sv-vbadge-vb" + c + "fx-count";
//         d = document.createElement("div");
//         d.className = "sv-vbadge-vb" + c + "fx-word";
//         if ("2" == c) {
//             d.style.fontSize = a.font_size + "px";
//             d.style.marginTop = "6px";
//             d.style.marginBottom = a.mb_correct + "px";
//             if (1 > a.ratingcount) {
//                 d.style.marginTop = "6px";
//                 d.style.color = "#FFFFFF";
//                 d.style.lineHeight = "14px";
//             }
//             if (1 < a.ratingcount && "grey" == a.sealcolor) {
//                 d.style.marginTop = "11px";
//                 d.style.color = "#FFFFFF";
//             }
//         }
//         if ("3" == c) {
//             d.style.fontSize = a.font_size + "px";
//             d.style.marginTop = "6px";
//             d.style.marginBottom = a.mb_correct + "px";
//             if (1 > a.ratingcount) {
//                 d.style.marginTop = "8px";
//                 d.style.color = "#FFFFFF";
//                 d.style.lineHeight = "14px";
//             }
//             if (1 < a.ratingcount && "grey" == a.sealcolor) {
//                 d.style.marginTop = "11px";
//                 d.style.color = "#FFFFFF";
//             }
//         }
//         // "2" == c && (d.style.fontSize = a.font_size + "px", d.style.marginTop = "6px", d.style.marginBottom = a.mb_correct + "px", 1 > a.ratingcount &&
//         //     (d.style.marginTop = "6px", d.style.color = "#FFFFFF", d.style.lineHeight = "14px"), 1 < a.ratingcount && "grey" == a.sealcolor && (d.style.marginTop = "11px", d.style.color = "#FFFFFF"));
//         // "3" == c && (d.style.fontSize = a.font_size + "px", d.style.marginTop = "6px", d.style.marginBottom = a.mb_correct + "px", 1 > a.ratingcount && (d.style.marginTop = "8px", d.style.color = "#FFFFFF", d.style.lineHeight = "14px"), 1 < a.ratingcount && "grey" == a.sealcolor && (d.style.marginTop = "11px", d.style.color = "#FFFFFF"));
//         d.innerHTML = 1 > a.ratingcount ? '<span style="line-height: 14px;">' +
//             a.ratingword + "</span>" : "<span>" + a.ratingword + "</span>";
//         var g = document.createElement("div");
//         g.className = "sv-vbadge-vb" + c + "fx-html";
//         g.innerHTML = a.repHTML;
//         var v = document.createElement("div");
//         v.className = "sv-rbadge-rb1fxb";
//         v.id = "sv-rbadge-rb1fxb";
//         var q = document.createElement("a");
//         q.href = "https://www.shopvote.de/" + a.reviewpage;
//         q.target = "_blank";
//         q.title = "Bewertungen bei ShopVote ansehen";
//         var u = document.createElement("div");
//         u.className = "sv-rbadge-rb1fxb-logo";
//         var n = document.createElement("img");
//         n.className =
//             "sv-rbadge-rb1fxb-logoimg";
//         n.src = "https://img.shopvote.de/profile-seal-160-" + a.sealcolor + ".png";
//         u.appendChild(n);
//         n = document.createElement("div");
//         n.className = "sv-rbadge-rb1fxb-wrapper";
//         n.id = "sv-rbadge-rb1fxb-wrapper";
//         var p = document.createElement("div");
//         p.className = "sv-rbadge-rb1fxb-close";
//         p.innerHTML = "<span onClick=\"document.getElementById('sv-rbadge-rb1fxb').style.display='none'; return false;\"> &#215; </span>";
//         document.createElement("div").className = "sv-rbadge-rb1fxb-logo";
//         var z = document.createElement("div");
//         z.className = "sv-rbadge-rb1fxb-data";
//         z.innerHTML = a.repHTMLbottom;
//         v.appendChild(n);
//         n.appendChild(p);
//         n.appendChild(q);
//         q.appendChild(u);
//         n.appendChild(z);
//         f.appendChild(l);
//         l.appendChild(b);
//         f.appendChild(d);
//         1 <= a.ratingcount && f.appendChild(g);
//         my_div = document.body.lastChild;
//         if ("0" == HideOnMobile) {
//             document.body.insertBefore(f, my_div);
//             if (1 == a.responsive) {
//                 document.body.insertBefore(v, my_div);
//             }
//         }
//         if (1 == a.SDAutoCode && 1 == a.activeStars) {
//             a = '{\t"@context":"https://schema.org",  "@type":"Organization",  "@id":"' + a.ratingurl + '#organization",  "name":"' + a.ratingname + '",  "url":"' + a.ratingurl + '",  "aggregateRating": {  "@type":"AggregateRating",  "worstRating":"1",  "bestRating":"5",  "ratingValue":"' + a.ratingvalue + '",  "reviewCount":"' + a.ratingcount + '",  "sameAs":"https://www.shopvote.de/' + a.reviewpage + '" }}';
//             TSR = document.head || document.getElementsByTagName("head")[0];
//             JR = ShopVoteJSONReviews = document.createElement("script");
//             JR.setAttribute("id", "ShopVoteJSONLd");
//             JR.type = "application/ld+json";
//             JR.appendChild(document.createTextNode(a));
//             TSR.appendChild(JR);
//         }
//         // "0" == HideOnMobile && (document.body.insertBefore(f, my_div), 1 == a.responsive && document.body.insertBefore(v, my_div));
//         // 1 == a.SDAutoCode && 1 == a.activeStars && (a = '{\t"@context":"https://schema.org",  "@type":"Organization",  "@id":"' + a.ratingurl + '#organization",  "name":"' +
//         //     a.ratingname + '",  "url":"' + a.ratingurl + '",  "aggregateRating": {  "@type":"AggregateRating",  "worstRating":"1",  "bestRating":"5",  "ratingValue":"' + a.ratingvalue + '",  "reviewCount":"' + a.ratingcount + '",  "sameAs":"https://www.shopvote.de/' + a.reviewpage + '" }}', TSR = document.head || document.getElementsByTagName("head")[0], JR = ShopVoteJSONReviews = document.createElement("script"), JR.setAttribute("id", "ShopVoteJSONLd"), JR.type = "application/ld+json", JR.appendChild(document.createTextNode(a)), TSR.appendChild(JR));
//         panel_div = document.body.lastChild;
//         "0" == HideOnMobile && document.body.insertBefore(m, panel_div);
//         document.getElementById("sv-panel-box").addEventListener("mouseleave", closePanel, !1)
//     }
//     css(c);
//     myLanguage = "undefined" == typeof myLanguage ? "de" : myLanguage.toLowerCase();
//     e = "https://widgets.shopvote.de/votebadge.php?shopid=" + e + "&type=" + c + "&src=" + x + "&lang=" + myLanguage;
//     x = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//     "undefined" === typeof r && (r = "right");
//     "undefined" === typeof t && (t =
//         "bottom");
//     if ("undefined" === typeof k && "undefined" !== typeof h) {
//         k = h;
//         h = "2";
//     }
//     // "undefined" === typeof k && "undefined" !== typeof h && (k = h, h = "2");
//     "undefined" === typeof h && (h = "0");
//     "undefined" === typeof k && (k = "5");
//     2 > h && (h = "0");
//     5 > k && (k = "5");
//     "undefined" === typeof y && (HideOnMobile = "0");
//     isNaN(y) || (HideOnMobile = "1");
//     "1" == HideOnMobile && x >= y && (HideOnMobile = "0");
//     var w = new XMLHttpRequest;
//     w.onreadystatechange = function () {
//         4 === w.readyState && 200 === w.status && A(w.responseText, h, k)
//     };
//     w.open("GET", e);
//     w.send()
// };