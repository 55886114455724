import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { Helmet } from 'react-helmet';

const NotFound = ({ location }) => {
		const { t } = useTranslation();
		const { pathname } = location;
		const { getSiteName } = useLocaleConfigSelectors();
		const siteName = getSiteName;

    return (
        <Fragment>
            <Helmet>
                <title>{t('Not found')} | {siteName} </title>
                <meta
                    name="description"
                    content={t('Not found') + ' | ' + siteName}
                />
            </Helmet>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>{t('Homepage')}</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                {t('Not found')}
            </BreadcrumbsItem>
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb />
                <div className="error-area pt-40 pb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-8 text-center">
                                <div className="error">
                                    <h1>404</h1>
                                    <h2>{t('Sorry, the page was not found')}</h2>
                                    <Link to={process.env.PUBLIC_URL + "/"} className="error-btn mt-30">
                                        {t('Back to homepage')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

NotFound.propTypes = {
    location: PropTypes.object
};

export default NotFound;
