import config from '../config/config';
import { getCustomerId } from '../redux/api/cart';
import { getTheliaToken, getUserIdentifier, setToken } from '../helpers/session';

export async function refreshToken() {
	// console.log('REFRESH TOKEN: Refreshing token at date:', new Date());
	try {
		const customer_id = getCustomerId();
		const email = getUserIdentifier();

		if (!customer_id || !email) {
			throw new Error('Missing customer_id or email in local storage');
		}

		const response = await fetch(`${config.apiUrl}/api/refreshToken`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${getTheliaToken()}`
			},
			body: JSON.stringify({ customer_id, email }),
		});

		if (!response.ok) {
			throw new Error('Failed to refresh token');
		}

		const result = await response.json();
		// console.log('REFRESH TOKEN: Token refreshed at date:', new Date());
		// console.log('REFRESH TOKEN: New Token:', result.token);

		setToken(result.token);

	} catch (error) {
		console.error('Error refreshing token:', error);
	}
}
