import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useWhyDidYouUpdate } from 'ahooks';
import { useGetCategoriesQuery } from '../redux/service/fastApiService';
import config from '../config/config';
import { setCategoriesIdList } from '../redux/slices/appSlice';

const useCategoriesInRTK = (time) => {
  const categoriesQuery = useGetCategoriesQuery({}, {
    refetchOnMountOrArgChange: time ?? config.refetchTimingShort,
  });
  
  const { data: categories, isSuccess: isSuccessCategories } = categoriesQuery;
  const dispatch = useDispatch();
  
  // useWhyDidYouUpdate('useCategoriesInRTK', { externalApiCategoriesCount: categories, isSuccessCategories });
  useEffect(() => {
    if (isSuccessCategories) {
      dispatch(setCategoriesIdList(categories));
    }
  }, [isSuccessCategories])
  
  return categoriesQuery;
};

export default useCategoriesInRTK;
