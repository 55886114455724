import React from 'react';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import isEmpty from 'lodash/isEmpty.js';

const TextDisplay = () => {
	
	const { getTextHeaderInfo: info } = useLocaleConfigSelectors();
	
	if(isEmpty(info)) return null;
	
	const { text, text_more, icon, color } = info;
	return (
		<div style={{ height: '100%', display: 'flex', alignItems: 'center' }} className="deliveryTopMob col-xl-9 col-lg-9 col-md-6 col-6">
			<p style={{ color: color }}>
				<i className={icon} aria-hidden="true"></i>{" "}
				{text} <span className="d-none-mobile">{text_more}</span>
			</p>
		</div>
	);
};
export default TextDisplay;
