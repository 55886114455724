import './utils/sentry.js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getCookieConsentValue } from 'react-cookie-consent';
import App from './App';
import * as serviceWorker from './serviceWorker';
import storeToolkit from './redux/store';
import GTMConsentManager from './helpers/GTMConsentManager';
import FaErrorPage from './pages/other/FaErrorPage.js';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import config_src from './config/config.js';

// Set up Google Tag Manager script
window.dataLayer = window.dataLayer || [];
window.gtag = function () { window.dataLayer.push(arguments); }; // Define gtag globally
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});
(function () {
  //var gtmId = 'GTM-5B8RLRZ'; // Replace this with dynamic value if needed
	var gtmId = config_src.gtmId;
  var gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtmId;
  document.head.appendChild(gtagScript);

  gtagScript.onload = function () {
    gtag('js', new Date());
    gtag('config', gtmId);
  };
})();
const { updateConsent } = GTMConsentManager();
// Check for CookieConsentGA cookie and update consent if granted
if (getCookieConsentValue("CookieConsentGA") === 'granted') {
  updateConsent(true);
}

const currentDomain = window.location.hostname;

if (process.env.REACT_APP_DOMAIN_TYPE === 'hausfabrik') {
  require('./assets/scss/styles_hausfabrik.scss');
} else {
  require('./assets/scss/style.scss');
}

const rootNode = document.getElementById('root');

// This is the old way of running the entire app.
// ReactDOM.render(
// 	<Provider store={store}>
// 		<ErrorBoundary fallback={<FaErrorPage />}>
// 			<Sentry.ErrorBoundary fallback={<FaErrorPage />}>
// 				<App />
// 			</Sentry.ErrorBoundary>
// 		</ErrorBoundary>
// 	</Provider>,
// 	rootNode
// );


// This is for React 18.2.0.
const root = createRoot(rootNode);

let logError = (error, componentStack) => {
	console.log("Error from index.js: ", error);
	console.error(error, componentStack);
};
root.render(
  <Provider store={storeToolkit}>
    <ErrorBoundary fallback={<FaErrorPage />} onError={logError}>
      <Sentry.ErrorBoundary fallback={<FaErrorPage />}>
        <App />
      </Sentry.ErrorBoundary>
    </ErrorBoundary>
  </Provider>
);

// Dynamically change favicon based on domain type
const setFavicon = () => {
  const faviconLink = document.createElement('link');
  faviconLink.rel = 'icon';
  faviconLink.type = 'image/x-icon';

  if (process.env.REACT_APP_DOMAIN_TYPE === 'hausfabrik') {
    faviconLink.href = `${process.env.PUBLIC_URL}/favicon_hausfabrik.ico`;
  } else {
    faviconLink.href = `${process.env.PUBLIC_URL}/favicon.ico`;
  }

  document.head.appendChild(faviconLink);
};

// Ensure this runs after the document is fully loaded
window.onload = setFavicon;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
