import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config_src from '../config/config';
import { setLocaleConfig } from '../redux/slices/locale_configSlice';
import { useGetLocaleQuery } from '../redux/service/fastApiService';
import { setCountry, setInitDone } from '../redux/slices/countrySlice';
import useLocaleConfigSelectors from './useLocaleConfigSelectors';
import { useLocalStorageState } from 'ahooks';
import constants from '../config/localStorageConstants.js';
import config_hard from '../config/config_hard.js';

const { locale_config } = config_hard;

const useLocalRedux = () => {
  const dispatch = useDispatch();
  const init = useSelector(state => state.country.initDone);
	const { country_id, tax_title } = constants;
	
	const fetch = useGetLocaleQuery(
		{},
		{
			refetchOnMountOrArgChange: config_src.refetchTimingLong,
		}
	);
  const { data: response, isSuccess } = fetch;
  const {getTaxTitle, getCountryId, getCountryTitle} = useLocaleConfigSelectors();
	const [, setCountryId] = useLocalStorageState(country_id, {listenStorageChange: true})
	const [, setTaxTitle] = useLocalStorageState(tax_title, {listenStorageChange: true})

	useEffect(() => {
		if (isSuccess) {
			const extractedKeys = Object.keys(response)
				.filter(key => locale_config.includes(key))
				.map(key => ({
					key,
					value:
						key === 'tax_serialized_requirements'
							? JSON.parse(atob(response[key]))
							: response[key],
				}));

			const extractedKeyValuePairs = extractedKeys.reduce(
				(accumulator, { key, value }) => {
					accumulator[key] = value;
					return accumulator;
				},
				{}
			);
			const { store_name, lang_code } = extractedKeyValuePairs;
			if (
				store_name &&
				lang_code
			) {
				extractedKeyValuePairs.site_name = `${store_name}.${lang_code}`.toUpperCase();
			}
			// Dispatch the action to save the config in the redux store
			dispatch(setLocaleConfig(extractedKeyValuePairs));
      localStorage.setItem('locale_config', JSON.stringify(extractedKeyValuePairs));
		}
	}, [isSuccess]);
  
  useEffect(() => {
    if (getTaxTitle && getCountryId && getCountryTitle && !init) {
	      const countryObject = {
	        id: getCountryId,
	        title: getCountryTitle,
	        tax_title: getTaxTitle,
	      };
	      // console.log("Setting a new country object in the redux store", countryObject);
	      dispatch(setCountry(countryObject));
				dispatch(setInitDone());
				
	      setCountryId(countryObject.id)
	      setTaxTitle(countryObject.tax_title)
    }
  }, [getTaxTitle, getCountryId, getCountryTitle,init, dispatch])
  
  return fetch;
};
export default useLocalRedux;
