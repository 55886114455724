import React from 'react';
import { useSelector } from 'react-redux';

function CountryDisplay() {
	// This component is just for testing on dev, to have a visibility on the values.
	
	// It will be not used in production ever.
	const selectedCountry = useSelector((state) => state.country?.selectedCountry);
	const { id, tax_title, title } = selectedCountry || {};
	
	const userRedux = useSelector(state => state.user?.userInfo);
	const tokenRedux = useSelector(state => state.user?.token);
	const loggedIn = useSelector(state => state.user?.userLoggedIn);
	const email = useSelector(state => state.user?.user_identifier);
	const profileName = useSelector(state => state.user?.profileName);
	const customerId = useSelector(state => state.user?.customer_id);
	const addressId = useSelector(state => state.user?.address_id);
	
	const headerSize = useSelector(state => state.header.size);
	
	return (
		<div>
			{/*<p>Selected Country: {selectedCountry ? `${id}, ${title} ${tax_title}` : 'None'}</p>*/}
			{/*<p>Selected User: {userRedux ? JSON.stringify(userRedux) : 'NONE'}</p>*/}
			{/*<p>Selected Token: {tokenRedux ? JSON.stringify(tokenRedux) : "NONE"}</p>*/}
			<p>Logged In: {loggedIn ? 'YES' : "NO"}</p>
			<p>Profile Name: {profileName || ""}</p>
			<p>email: {email || ""}</p>
			<p>customerId: {customerId || ""}</p>
			<p>addressId: {addressId || ""}</p>
			<p>Header Size: {headerSize}</p>
		</div>
	);
}

export default CountryDisplay;
