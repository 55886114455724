const config_hard = {
	// development || production
	mode: 'development',
	version: '1.0.6',
	keywordGroupString: "badges",
	domain: 'localhost',
	refetchTimingShort: 60,
	refetchTimingLong: 30,
	locale_config: [
		'lang_id',
		'lang_locale',
		'lang_url',
		'country_id',
		'tax_tax_id',
		'tax_tax_title',
		'tax_serialized_requirements',
		'store_name',
		'lang_code',
		'currency_currency_code',
		'loading_gif',
		'currency_currency_symbol',
		'site_key',
		'captcha_site_key',
		'logo_image',
		'sal',
		'anpc',
		'display_buttons_ANPC',
		'default_product_image',
		'widerrufsformular_pdf_link',
		'content_ids',
		'logo_footer',
		'logo_header',
		'home_team_img',
		'homepage_products',
		'hero_slider_twelve',
		'vorkasse_info',
		'country_title',
		'ioons_mail_picture',
		'ioons_facebook_url',
		'ioons_instagram_url',
		'ioons_youtube_url',
		'shopvote_shop_id',
		'trusted_shop_id',
		'shopvote_display',
		'trustedshop_display',
		'phone_regex',
		'footer_image_1',
		'footer_image_2',
		'footer_image_3',
		'footer_image_4',
		'feature_icon_eight',
		'testimonial_one',
		'guarantee_links',
		'advent',
		'advent_bg',
		'advent_gift',
		'vat_min_length',
		'textInCart',
		'multiplier',
		'content_with_images',
		'best_sellers_products_homepage_ids',
		'brand_logos_homepage',
		'banners_homepage',
		'country_visible',
		'config_min_price',
		'country_flag',
		'display_country_header',
		'is_phone_required',
		'series_menu',
		'ioons_tiktok_url',
		'text-header-info',
	]
};

export const requiredConfigs = ['apiUrl', 'fastApi', 'token', 'language', 'locale'];
export const validateConfig = (config) => {
	const missingConfigs = requiredConfigs.filter(prop => {
		const value = config[prop];
		return value == null || value === '';
	});
	
	return {
		isValid: missingConfigs.length === 0,
		missingConfigs
	};
};


export default config_hard;
