import { createSlice } from '@reduxjs/toolkit';
import config from '../../config/config';

const initialState = {
    selectedCountry: config.countryDefault,
		initDone: false,
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
	    
				setInitDone: (state) => {
						state.initDone = true;
				},
				setInitDoneFalse: (state) => {
						state.initDone = false;
				}
    },
});

export const { setCountry,setInitDone,setInitDoneFalse } = countrySlice.actions;

export default countrySlice.reducer;
