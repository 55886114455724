import React, { useEffect, useState } from 'react';
import useTokenMonitor from './hooks/useTokenMonitor';
import { refreshToken } from './utils/refreshToken';
import { setToken as setTokenUtil } from './helpers/session';

const tokenName = 'jwtToken';

const TokenProvider = ({ children }) => {
	const tokenChecked = useTokenMonitor();
	const [worker, setWorker] = useState(null);

	const setToken = (token) => {
		setTokenUtil(token);
		initializeWorker();
	};

	const initializeWorker = () => {
		// console.log("TOKEN PROVIDER: Initializing Worker at date:", new Date());
		if (worker) {
			// console.log("TOKEN PROVIDER: Terminating Worker at date:", new Date());
			worker.terminate();
		}

		if (window.Worker) {
			// console.log("TOKEN PROVIDER: Creating Worker at date:", new Date());

			const newWorker = new Worker(`${process.env.PUBLIC_URL}/tokenWorker.js`);
			setWorker(newWorker);

			const tokenCookie = JSON.parse(localStorage.getItem(tokenName));
			if (tokenCookie) {
				const storedDate = new Date(tokenCookie.expiresAt);
				const now = new Date();
				const initialLife = storedDate - now;

				// console.log("TOKEN PROVIDER: Stored Date:", storedDate);
				// console.log("TOKEN PROVIDER: Initial Life:", initialLife);
				// console.log("TOKEN PROVIDER: Token expires at:", tokenCookie.expiresAt);
				// console.log("TOKEN PROVIDER: Token should refresh in:", initialLife * 0.66 * 60000, 'minutes')
				// console.log("TOKEN PROVIDER: Token should refresh at:", new Date(now.getTime() + initialLife * 0.66));


				newWorker.postMessage({ expiresAt: tokenCookie.expiresAt, initialLife });

				newWorker.onmessage = async function (e) {
					const { status, remainingMinutes } = e.data;
					// console.log('TOKEN PROVIDER: Token status:', status, remainingMinutes, 'minutes')
					if (status === 'expired' && remainingMinutes <= 0) {
						// console.log('TOKEN PROVIDER: Token expired at date:', new Date());
						localStorage.removeItem(tokenName);
					} else if (status === 'two_thirds') {
						// console.log("TOKEN PROVIDER: Condition met at date:", new Date());
						// console.log('TOKEN PROVIDER: Token will expire in', remainingMinutes, 'minutes');
						await refreshToken();
						initializeWorker(); // Ensure the worker is reinitialized with the new token
					}
				};
			}
		}
	};

	useEffect(() => {
		if (tokenChecked) {
			initializeWorker();
		}

		return () => {
			if (worker) {
				worker.terminate();
			}
		};
	}, [tokenChecked]);

	return <>{children}</>;
};

export default TokenProvider;
