import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	size: 100,
};

export const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setHeader: (state, action) => {
			state.size = action.payload;
		},
	},
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
