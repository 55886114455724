import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import useFetchData from '../../hooks/useFetchDataNestedRouter';

const AdminHeader = ({ storeName, backOfficePath }) => {
  const [isStoreMenuOpen, setIsStoreMenuOpen] = useState(false);
  const [isToolsMenuOpen, setIsToolsMenuOpen] = useState(false);

// Function to clean up URL parameters
  const cleanURL = (url) => {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('_gl');
    return urlObj.toString();
  };
  // Event handlers for hover
  const handleStoreMenuHover = () => {
    setIsStoreMenuOpen(true);
    setIsToolsMenuOpen(false); // Close tools menu
  };
  const handleToolsMenuHover = () => {
    setIsToolsMenuOpen(true);
    setIsStoreMenuOpen(false); // Close store menu
  };
  // Close menus on mouse leave
  const handleMenuMouseLeave = () => {
    setIsStoreMenuOpen(false);
    setIsToolsMenuOpen(false);
  };

  const match = useRouteMatch('/*');
  const searchString = match.url.substring(1);
  const location = useLocation();

  const { rewritingResult, productResult, categoryResult, contentResult, isProduct, isCategory, isContent } = useFetchData(searchString);
  const { data: rewritingData, error: rewritingError, isLoading: rewritingLoading, isError: isRewritingError, isSuccess } = rewritingResult;
  const { data: productData, error: productError, isLoading: productLoading, isError: isProductError, isSuccess: isSuccessProduct  } = productResult;
  const { data: categoryData, error: categoryError, isLoading: categoryLoading, isError: isCategoryError, isSuccess: isSuccessCategory } = categoryResult;
  const { data: contentData, error: contentError, isLoading: contentLoading, isError: isContentError } = contentResult;



return (
<div className="dropdown" onMouseLeave={handleMenuMouseLeave}>
   <li className='dropdown'>
      <button className="dropdown-button dropdown-toggle" onMouseEnter={handleStoreMenuHover}>
      <span className="fa fa-dashboard"></span>{' '}
      {storeName}
      </button>
      {isStoreMenuOpen && (
      <ul className="dropdown-menu">
         <li>
            <a href={cleanURL(`${backOfficePath}/customers`)} target="_blank" rel="noopener noreferrer">Customers</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/orders`)} target="_blank" rel="noopener noreferrer">Orders</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/categories`)} target="_blank" rel="noopener noreferrer">Catalog</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/folders`)} target="_blank" rel="noopener noreferrer">Folders</a>
         </li>
      </ul>
      )}
   </li>

   <li className='dropdown'>
      <button className="dropdown-button dropdown-toggle" onMouseEnter={handleToolsMenuHover}>
      <span className="fa fa-cube"></span>{' '}
      Tools
      </button>
      {isToolsMenuOpen && (
      <ul className="dropdown-menu">
         <li>
            <a href={cleanURL(`${backOfficePath}/coupon`)} target="_blank" rel="noopener noreferrer">Coupons</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/sales`)} target="_blank" rel="noopener noreferrer">Sales management</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/brand`)} target="_blank" rel="noopener noreferrer">Brands</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/export`)} target="_blank" rel="noopener noreferrer">Export</a>
         </li>
         <li>
            <a href={cleanURL(`${backOfficePath}/import`)} target="_blank" rel="noopener noreferrer">Import</a>
         </li>
      </ul>
      )}
   </li>
  
   <li className='dropdown'>
      <button className="dropdown-button">
        <span className="fa fa-plug"></span>{' '}
        <a href={cleanURL(`${backOfficePath}/modules`)} target="_blank" rel="noopener noreferrer">Modules</a>
      </button>
   </li>

   <li className='dropdown'>
      <button className="dropdown-button">
        <span className="fa fa-cogs"></span>{' '}
        <a href={cleanURL(`${backOfficePath}/configuration`)} target="_blank" rel="noopener noreferrer">Configuration</a>
      </button>
   </li>

  { isSuccess && (isCategory || isProduct) && (
   <li className='dropdown'>
      <button className="dropdown-button">
        <span className="fa fa-edit"></span>{' '}
        <a href={cleanURL(`${backOfficePath}/categories/update?category_id=${isProduct ? productData?.DEFAULT_CATEGORY : rewritingData.view_id}`)} target="_blank" rel="noopener noreferrer">Update category</a>
      </button>
   </li>
  )}

  { isSuccess && isProduct && 
    <>
     <li className='dropdown'>
        <button className="dropdown-button">
          <span className="fa fa-edit"></span>{' '}
          <a href={cleanURL(`${backOfficePath}/products/update?product_id=${rewritingData.view_id}`)} target="_blank" rel="noopener noreferrer">Update product</a>
        </button>
     </li>

     <li className='dropdown'>
        <button className="dropdown-button">
          <span className="fa fa-edit"></span>{' '}
          <a href={cleanURL(`${backOfficePath}/module/revenue-wholesale-partner-product?product_id=${rewritingData.view_id}`)} target="_blank" rel="noopener noreferrer">Update partner product</a>
        </button>
     </li>
    </>
  }

  { isSuccess && isContent && (
   <li className='dropdown'>
      <button className="dropdown-button">
        <span className="fa fa-edit"></span>{' '}
        <a href={cleanURL(`${backOfficePath}/content/update/${rewritingData.view_id}`)} target="_blank" rel="noopener noreferrer">Update content</a>
      </button>
   </li>
  )}
  
</div>
);
};
export default AdminHeader;
