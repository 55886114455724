import React from 'react';

const LoadingStatus = () => {
	return <div className="flone-preloader-wrapper">
		<div className="flone-preloader">
			<span />
			<span />
		</div>
	</div>;
};

export default LoadingStatus;
