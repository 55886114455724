import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
import { ioonsApi } from './service/ioonsService';
import { fastApi } from './service/fastApiService';
import rootReducer from './reducers/rootReducer';

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(save())
			.concat(ioonsApi.middleware)
			.concat(fastApi.middleware),
	preloadedState: load(),
});
export default store;
