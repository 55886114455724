import {SET_CART_ID} from "../actions/cartActions";

const initState = [];

const globalCartReducer = (state = initState, action) => {
    const cartItems = state, product = action.payload;


    if (action.type === SET_CART_ID) {
        return {
            ...state,
            cartId: action.payload
        }
    }
    return state;
};

export default globalCartReducer;
