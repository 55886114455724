import React, { useEffect, useState } from "react";
import useLocaleConfigSelectors from '../../../helpers/useLocaleConfigSelectors.js';
import { useGetSystemAllQuery } from '../../../redux/service/fastApiService.js';

const MobileWidgets = () => {
  const {
    getIoonsFacebookUrl,
    getIoonsInstagramUrl,
    getIoonsYoutubeUrl,
    getIoonsMailPicture: mailPicture
  } = useLocaleConfigSelectors();

  const { data: { storePhone } } = useGetSystemAllQuery({}, {
    refetchOnMountOrArgChange: true,
    selectFromResult: (result) => ({
      ...result,
      data: {
        storePhone: result.data ? result.data['store_phone'] : "",
      }
    }),
  });
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href={'tel:' + storePhone}>{storePhone}</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a>
                <img style={{ width: "40%" }} alt="" src={process.env.PUBLIC_URL + mailPicture} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
        <a
          href={getIoonsFacebookUrl}
          target="_blank"
          className="social-icon social-icon-facebook"
          rel="noopener noreferrer nofollow"
          title="Facebook"
        >
          <svg className="contact-social-facebook" fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.996 16.091c-0.001-8.281-6.714-14.994-14.996-14.994s-14.996 6.714-14.996 14.996c0 7.455 5.44 13.639 12.566 14.8l0.086 0.012v-10.478h-3.808v-4.336h3.808v-3.302c-0.019-0.167-0.029-0.361-0.029-0.557 0-2.923 2.37-5.293 5.293-5.293 0.141 0 0.281 0.006 0.42 0.016l-0.018-0.001c1.199 0.017 2.359 0.123 3.491 0.312l-0.134-0.019v3.69h-1.892c-0.086-0.012-0.185-0.019-0.285-0.019-1.197 0-2.168 0.97-2.168 2.168 0 0.068 0.003 0.135 0.009 0.202l-0.001-0.009v2.812h4.159l-0.665 4.336h-3.494v10.478c7.213-1.174 12.653-7.359 12.654-14.814v-0z"></path>
          </svg>
        </a>
        <a
          href={getIoonsInstagramUrl}
          target="_blank"
          className="social-icon social-icon-insta"
          rel="noopener noreferrer nofollow"
          title="Instagram"
        >
          <svg className="contact-social-instagram" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
            <defs>
              <radialGradient id="instagramGradient" cx="30%" cy="107%" r="107%" fx="30%" fy="107%">
                <stop offset="0%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
                <stop offset="5%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
                <stop offset="45%" style={{ stopColor: '#fd5949', stopOpacity: 1 }} />
                <stop offset="60%" style={{ stopColor: '#d6249f', stopOpacity: 1 }} />
                <stop offset="90%" style={{ stopColor: '#285AEB', stopOpacity: 1 }} />
              </radialGradient>
            </defs>
            <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" className="instagram-icon" />
          </svg>
        </a>
        <a
          href={getIoonsYoutubeUrl}
          target="_blank"
          className="social-icon social-icon-youtube"
          rel="noopener noreferrer nofollow"
          title="YouTube"
        >
          <svg className="contact-social-youtube" width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
          </svg>
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
