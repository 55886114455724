import React, { useEffect } from 'react';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';

const TrustedShopsWidget = () => {
	
		const {
			getTrustedShopDisplay: TrustedShopDisplay,
			getShopvoteDisplay: ShopvoteDisplay,
			getTrustedShopId
		} = useLocaleConfigSelectors();
    useEffect(() => {
        const _tsid = getTrustedShopId;
        const _ts = document.createElement('script');
        _ts.type = 'text/javascript';
        _ts.charset = 'utf-8';
        _ts.async = true;
        _ts.src = `//widgets.trustedshops.com/js/${_tsid}.js`;
        // Set the data attributes for widget configuration
        _ts.setAttribute('data-mobile-position', 'right');
        _ts.setAttribute('data-mobile-y-offset', '-10');
        if(ShopvoteDisplay.badge === true && TrustedShopDisplay.badge === true) {
            _ts.setAttribute('data-desktop-y-offset', '107');
        } else {
            _ts.setAttribute('data-desktop-y-offset', '-48');
        }
        /*_ts.setAttribute('data-desktop-y-offset', '0');
        _ts.setAttribute('data-mobile-y-offset', '0');
        _ts.setAttribute('data-desktop-disable-reviews', 'false');
        _ts.setAttribute('data-desktop-enable-custom', 'false');
        _ts.setAttribute('data-desktop-position', 'right');
        _ts.setAttribute('data-desktop-custom-width', '156');
        _ts.setAttribute('data-desktop-enable-fadeout', 'false');
        _ts.setAttribute('data-disable-mobile', 'false');
        _ts.setAttribute('data-disable-trustbadge', 'false');
        _ts.setAttribute('data-mobile-custom-width', '156');
        _ts.setAttribute('data-mobile-disable-reviews', 'false');
        _ts.setAttribute('data-mobile-enable-custom', 'false');
        _ts.setAttribute('data-mobile-position', 'left');
        _ts.setAttribute('data-mobile-enable-topbar', 'false');
        _ts.setAttribute('data-mobile-enable-fadeout', 'true');
        _ts.setAttribute('data-color-scheme', 'light');*/
        const __ts = document.getElementsByTagName('script')[0];
        __ts.parentNode.insertBefore(_ts, __ts);

        return () => {
            // Clean up the Trusted Shops script when the component unmounts
            __ts.parentNode.removeChild(_ts);
        };
    }, []);

    return <div id="CustomTrustbadge" />;
};

export default TrustedShopsWidget;
