// src/utils/tokenUtils.js
export function checkToken(tokenName) {
	const tokenCookie = JSON.parse(localStorage.getItem(tokenName));
	if (tokenCookie) {
		const storedDate = new Date(tokenCookie.expiresAt);
		const now = new Date();
		// console.log(`Stored Date: ${storedDate}`);
		// console.log(`Current Date: ${now}`);
		const remainingTime = storedDate - now;

		if (remainingTime <= 0) {
			// console.log('Token has expired');
			return false;
		} else {
			const remainingMinutes = Math.floor(remainingTime / 60000);
			// console.log(`Token is valid, remaining life: ${remainingMinutes} minutes`);
			return true;
		}
	} else {
		// console.log('No token found');
		return false;
	}
}
