import {combineReducers} from "redux";
import {createMultilanguageReducer} from "redux-multilanguage";
import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import globalCartReducer from "./globalCartReducer";
import { ioonsApi } from '../service/ioonsService';
import { fastApi } from '../service/fastApiService';
import localeConfigReducer from '../slices/locale_configSlice';
import countryReducer from '../slices/countrySlice';
import userReducer from '../slices/userSlice';
import headerReducer  from '../slices/headerSlice';
import appReducer from '../slices/appSlice';

const rootReducer = combineReducers({
	[ioonsApi.reducerPath]: ioonsApi.reducer,
	[fastApi.reducerPath]: fastApi.reducer,
	multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
	currencyData: currencyReducer,
	productData: productReducer,
	cartData: cartReducer,
	wishlistData: wishlistReducer,
	compareData: compareReducer,
	globalCartData: globalCartReducer,
	localeConfig: localeConfigReducer,
	country: countryReducer,
	user: userReducer,
	header: headerReducer,
	app: appReducer,
});

export default rootReducer;
