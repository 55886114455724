const GTMConsentManager = () => {

    const updateConsent = (adConsent) => {
        gtag('consent', 'update', {
            'ad_storage': adConsent ? 'granted' : 'denied',
            'ad_user_data': adConsent ? 'granted' : 'denied',
            'ad_personalization': adConsent ? 'granted' : 'denied',
            'analytics_storage': adConsent ? 'granted' : 'denied'
        });

        window.dataLayer.push({
            event: 'consentUpdated',
            ad_storage: adConsent ? 'granted' : 'denied',
            ad_user_data: adConsent ? 'granted' : 'denied',
            ad_personalization: adConsent ? 'granted' : 'denied',
            analytics_storage: adConsent ? 'granted' : 'denied'
        });
    };

    return { updateConsent };
};

export default GTMConsentManager;