import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors';
import { setCountry } from '../../redux/slices/countrySlice';
import constants from '../../config/localStorageConstants';
import { useLocalStorageState } from 'ahooks';

const CountryDropdown = ({ countryDropdownInfo }) => {
  const selectedCountry = useSelector((state) => state.country?.selectedCountry);
	
	const { country_id,tax_title, excludeVAT } = constants;
	const isExcludeVAT = useSelector(state => state.user.excludeVAT);
	const [, setCountryId] = useLocalStorageState(country_id, {listenStorageChange: true})
	const [, setTaxTitle] = useLocalStorageState(tax_title, {listenStorageChange: true})
  const [isOpen, setIsOpen] = useState(false);
  const { getCountryFlag: countryFlag } = useLocaleConfigSelectors();
  const { t } = useTranslation();

  const dispatch = useDispatch();

   const handleSelect = (country) => {
	   dispatch(setCountry(country));
	   setTaxTitle(country.tax_title);
	   setCountryId(country.id);
	   setIsOpen(false);
   };

  // Render different UI based on 'excludeVAT' and 'countryDropdownInfo.length'
 const excludeVATIsTrue = isExcludeVAT === 'true';
  return excludeVATIsTrue ? (
    <div className='dropdown-countries col-xl-2' onClick={() => setIsOpen(false)}>
      {selectedCountry ? (
        <>
          {t('Delivery Country')}
          <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
          <p style={{ color: 'white' }}>0% {t('VAT')}</p>
        </>
      ) : (
        <>
          <i className='fa fa-caret-down'></i> {t('Delivery Country')}
        </>
      )}
    </div>
  ) : (
    countryDropdownInfo.length > 1 ? (
      <div className='dropdown col-xl-3 col-lg-3 col-md-6 col-6'>
        <div className='dropdown-countries' onClick={() => setIsOpen(!isOpen)}>
          {selectedCountry ? (
            <>
              <i className='fa fa-caret-down'></i>
              {t('Delivery Country')}
              <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
            </>
          ) : (
            <>
              <i className='fa fa-caret-down'></i> {t('Delivery Country')}
            </>
          )}
        </div>
        {isOpen && (
          <div className='dropdown-list'>
            {countryDropdownInfo.map((item, index) => (
              <div key={index}
                   className={`dropdown-item ${selectedCountry && selectedCountry.id === item.id ? 'selected-country' : ''}`}
                   onClick={() => handleSelect(item)}>
                <img src={countryFlag[item.id]} alt='' className='dropdown-flag' />
                {item.title} {item.tax_title}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      // Handling when there is only one item in 'countryDropdownInfo'
      <>
        {countryDropdownInfo.map((item, index) => (
          <div className='dropdown-item-single' key={index}>
            {t('Delivery Country')}
            <img src={countryFlag[item.id]} alt='' className='dropdown-flag selected-flag' />
          </div>
        ))}
      </>
    )
  );
};


export default CountryDropdown;
