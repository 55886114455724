import { useEffect, useState } from 'react';
import useLocaleConfigSelectors from '../helpers/useLocaleConfigSelectors';
import { useGetBrandQuery, useGetContentQuery, useGetRewritingUrlMutation } from '../redux/service/fastApiService';
import {
  useGetIndividualCategoriesQuery,
  useGetIndividualProductQuery,
  useGetPSEQuery,
} from '../redux/service/ioonsService';
import { useSelector } from 'react-redux';
import config_src from '../config/config';

const useFetchData = (searchString) => {
  const { getLangCode } = useLocaleConfigSelectors();
    // getLangCode = 'de_DE';
  const [getRewritingUrl, rewritingResult] = useGetRewritingUrlMutation();
  const { data: rewritingData, isSuccess: isRewritingSuccess } = rewritingResult;
  const [productId, setProductId] = useState(null);
  const [fetchProduct, setFetchProduct] = useState(false);

  useEffect(() => {
    if (getLangCode && searchString) {
      getRewritingUrl({ view_locale: getLangCode, view_url: searchString });
    }
  }, [getLangCode, searchString, getRewritingUrl]);


  const viewId = rewritingData?.view_id;
  
  const { id: countryId } = useSelector((state) => state.country?.selectedCountry);

  let productSearchParams = { productId: viewId, countryId };
  const pseSearchParams = { PRODUCT_SALE_ELEMENT: viewId, countryId };

  const isProduct = isRewritingSuccess && rewritingData.view === 'product';
  const isCategory = isRewritingSuccess && rewritingData.view === 'category';
  const isContent = isRewritingSuccess && rewritingData.view === 'content';
  const isBrand = isRewritingSuccess && rewritingData.view === 'brand';
  const isPse = isRewritingSuccess && rewritingData.view === 'pse';
	
	const productResult = useGetIndividualProductQuery({ productId, countryId },		{ skip: !fetchProduct,
			refetchOnMountOrArgChange: config_src.refetchTimingShort,
		});
	
  const categoryResult = useGetIndividualCategoriesQuery(viewId, { skip: !isCategory,
	  refetchOnMountOrArgChange: config_src.refetchTimingShort,
  });
  const contentResult = useGetContentQuery(viewId, { skip: !isContent,
	  refetchOnMountOrArgChange: config_src.refetchTimingShort,
  });
  const brandResult = useGetBrandQuery(viewId, { skip: !isBrand,
	  refetchOnMountOrArgChange: config_src.refetchTimingShort,
  });
  const { data: pseResult, isSuccess: isPseSuccess } = useGetPSEQuery(pseSearchParams, { skip: !isPse,
	  refetchOnMountOrArgChange: config_src.refetchTimingShort,
	});

  useEffect(() => {
    if (isPse && isPseSuccess && pseResult.PRODUCT_ID) {
      setProductId(pseResult.PRODUCT_ID);
      setFetchProduct(true);
    }
  }, [isPseSuccess]);

  useEffect(() => {
    if (isProduct && viewId) {
      setProductId(viewId);
      setFetchProduct(true);
    }
  }, [isProduct, viewId]);
	
  return {
    rewritingResult,
    productResult,
    categoryResult,
    contentResult,
    brandResult,
    pseResult,
    isProduct,
    isCategory,
    isContent,
    isBrand,
    isPse,
  };
};

export default useFetchData;
