import { createSlice } from '@reduxjs/toolkit';

const initialLocaleConfigState = {};

export const localeConfigSlice = createSlice({
	name: 'localeConfig',
	initialState: initialLocaleConfigState,
	reducers: {
		setLocaleConfig: (state, action) => action.payload,
	},
});

export const { setLocaleConfig } = localeConfigSlice.actions;

export default localeConfigSlice.reducer;
