import { useEffect } from 'react';
import useWindowSize from './useWindowSize';
import { useTranslation } from "react-i18next";
import { useGetSystemAllQuery } from '../redux/service/fastApiService.js';
import config_src from '../config/config';

export const useZenDesk = () => {
	const locale = config_src.locale;
	const zendeskKey = config_src.zendeskKey;
	const { t } = useTranslation();
	const { data: { storeName, storeSupportTime } } = useGetSystemAllQuery({}, {
		refetchOnMountOrArgChange: config_src.refetchTimingLong,

		selectFromResult: (result) => ({
			...result,
			data: {
				storeName: result.data ? result.data['store_name'] : "",
				storeSupportTime: result.data ? result.data.support_time_mo_th : ''
			}
		}),
	});
	useEffect(() => {
		if (zendeskKey) {
			// Create a new script element
			const zendeskScript = document.createElement('script');
			zendeskScript.id = 'ze-snippet';
			zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
			const onScriptLoad = () => {
				if (window.zE && locale) {
					window.zE('webWidget', 'setLocale', locale);
					window.zESettings = {
						webWidget: {
							launcher: {
								badge: {
									label: {
										// '*': locale == 'ro_RO' ? 'Vorbeste cu noi' : 'Chatten Sie mit uns',
										'*': t('Chat with us'),
									}
								}
							},
							chat: {
								title: {
									'*': t('Welcome!')
								},
								concierge: {
									avatarPath: '/assets/img/zendesk/assistant.png',
									name: locale.includes("ro") ? t('Assistent') + " " + storeName : storeName + " " + t('Assistent'),
									title: { '*': t('Ask us') }
								},
								offlineForm: {
									greeting: {
										'*': storeSupportTime ? t('Program') + ' ' + storeSupportTime + ' • ' + t('We are currently not online. Leave a message. We will contact you shortly.') : t('We are currently not online. Leave a message. We will contact you shortly.')
									}
								}
							}
						}
					};
				}
			};
			zendeskScript.addEventListener('load', onScriptLoad);

			// Append the script to the body
			document.body.appendChild(zendeskScript);

			return () => {
				// Cleanup: Remove the script when the component unmounts
				document.body.removeChild(zendeskScript);
			};
		}
	}, [zendeskKey, locale]);
};
