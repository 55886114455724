import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotFound from './pages/other/NotFound';
import useFetchData from './hooks/useFetchDataNestedRouter';

const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'));
const Product = lazy(() => import('./pages/shop-product/Product'));
const Content = lazy(() => import('./pages/other/Content'));

let counter = 0;

function NestedRouter() {
    counter++;
    const match = useRouteMatch('/*');
    const searchString = match.url.substring(1);
    const location = useLocation();
    const { t } = useTranslation();
    const [brandId,setBrandId] = useState(null);
    const [categoryId,setCategoryId] = useState(null);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    const {rewritingResult, productResult, categoryResult, contentResult, brandResult, pseResult, isProduct, isCategory, isContent, isBrand, isPse} = useFetchData(searchString);
    const { data: rewritingData, error: rewritingError, isLoading: rewritingLoading, isError: isRewritingError } = rewritingResult;
    const { data: productData, error: productError, isLoading: productLoading, isError: isProductError,isSuccess: isSuccessProduct  } = productResult;
    const { data: categoryData, error: categoryError, isLoading: categoryLoading, isError: isCategoryError, isFetching:isCategoryFetching, isSuccess: isSuccessCategory} = categoryResult;
    const { data: contentData, error: contentError, isLoading: contentLoading, isError: isContentError, isSuccess: isSuccessContent } = contentResult;
    const { data: brandData, error: brandError, isLoading: brandLoading, isError: isBrandError} = brandResult;
		
    const showLoading = <div className="loading-message">{t('Please wait...')}</div>;

    const showNotFoundPage = isRewritingError || isProductError || isCategoryError || isContentError || isBrandError;
    if (match.path === '/404') {
        return <NotFound />;
    }
    const {PUBLIC_URL} = process.env;
    
    const renderRoute = (Component, data, additionalProps = {}) => (
        <Route
            path={`${PUBLIC_URL}/${searchString}`}
            render={routeProps => (
                <Component {...routeProps} {...additionalProps} key={routeProps.match.params.id} additionalProp={data} />
            )}
        />
    );

    return (
        <Suspense fallback={showLoading}>
            <div>
                <div>
                    {(isProduct || isPse) && isSuccessProduct && renderRoute(Product, productData)}
                    {isCategory && isSuccessCategory && categoryData && !isCategoryFetching &&  (

                        <Route
                            path={`${PUBLIC_URL}/${searchString}`}
                            render={routeProps => {
                                return <ShopGridStandard
                                  {...routeProps}
                                  additionalProp={categoryData}
                                  brandId={null}
                                  categoryId={categoryData.ID}
                                  title={categoryData.TITLE}
                                />;
                            }}
                        />
                    )}
                    {isBrand && brandData && (
                      <Route
                        path={`${PUBLIC_URL}/${searchString}`}
                        render={routeProps => {
                            return <ShopGridStandard
                              {...routeProps}
                              additionalProp={brandData}
                              brandId={brandData.brand_id}
                              categoryId={null}
                              title={brandData.brand_title}
                            />;
                        }}
                      />
                    )}
                    {isContent && isSuccessContent && (
                      // Check if both brandId and categoryId are not null
                      brandId && categoryId ? (
                        // Render ShopGridStandard if both IDs exist
                        <Route
                          path={`${PUBLIC_URL}/${searchString}`}
                          render={routeProps => {
                              return <ShopGridStandard
                                {...routeProps}
                                additionalProp={contentData}
                                brandId={brandId}
                                categoryId={categoryId}
                                title={contentData.title}
                              />;
                          }}
                        />
                      ) : (<Content contentData={contentData} />)
                    )}
                    {showNotFoundPage && <Route exact component={NotFound} />}
                </div>
            </div>
        </Suspense>
    );
}

export default NestedRouter;
