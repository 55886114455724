import React, { useEffect } from 'react';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';

const TrustedShopsWidget = () => {
	const {
		getTrustedShopId
	} = useLocaleConfigSelectors();
	
	useEffect(() => {
        const _tsid = getTrustedShopId;
        const _ts = document.createElement('script');
        _ts.type = 'text/javascript';
        _ts.charset = 'utf-8';
        _ts.async = true;
        _ts.src = `//widgets.trustedshops.com/js/${_tsid}.js`;
        _ts.setAttribute('data-desktop-enable-custom', 'true');
        _ts.setAttribute('data-mobile-enable-custom', 'true');
        const __ts = document.getElementsByTagName('script')[0];
        __ts.parentNode.insertBefore(_ts, __ts);

        return () => {
            // Clean up the Trusted Shops script when the component unmounts
            __ts.parentNode.removeChild(_ts);
        };
    }, []);

    return <div id="trustbadgeCustomMobileContainer"><div id="trustbadgeCustomContainer"><div id="CustomTrustbadge" /></div></div>;
};

export default TrustedShopsWidget;
