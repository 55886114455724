import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { useSize, useWhyDidYouUpdate } from 'ahooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import FullMenu from "../../components/header/FullMenu";
import CountryDropdown from "../../components/header/CountryDropdown";
import TextDisplay from "../../components/header/TextDisplay";
import AdminHeader from "../../components/header/AdminHeader";
import Appx from "../../components/react-elastic-thelia/elastic";
import useWindowSize from '../../helpers/useWindowSize';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors';
import { useGetConfigQuery } from '../../redux/service/ioonsService';
import { useGetTagManagerQuery } from '../../redux/service/fastApiService';
import CountryDisplay from '../../components/header/CountryDisplay';
import SalesBarComponent from './SalesBar';
import { setHeader } from '../../redux/slices/headerSlice';
import config_src from '../../config/config.js';


const tagManagerArgs = {
    gtmId: config_src.gtmId
}

const HeaderOne = ({
    layout,
    top,
    borderStyle,
    headerPaddingClass,
    headerPositionClass,
    headerBgClass
}) => {
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const { getLogoHeader, getCountryVisible: countryDropdownInfo, getStoreName, getDisplayCountryHeader } = useLocaleConfigSelectors();

    const { data: tagManager, isSuccess } = useGetTagManagerQuery({});
    const isAdmin = Boolean(useSelector(state => state.user.isAdmin));

    const headerRef = useRef(null);
    const size = useSize(headerRef);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!size) return;
        dispatch(setHeader(size.height));
    }, [size]);

    useEffect(() => {
        if (isSuccess) {
            const trackingCode = tagManager?.googletagmanager_trackingcode;
            if (trackingCode) {
                try {
                    eval(trackingCode);
                } catch (error) {
                    console.error("Failed to execute tracking code:", error);
                }
            }
        }
    }, [isSuccess, tagManager]);

    const [headerTop, setHeaderTop] = useState(0);
    // useEffect(() => {
    //     const header = document.querySelector(".sticky-bar");
    //     setHeaderTop(header.offsetTop);
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const [scroll, setScroll] = useState(0);
    // const handleScroll = () => {
    //     setScroll(window.scrollY);
    // };

    const { data: configApi, isSuccess: isSuccessConfigApi } = useGetConfigQuery();
    const [displayAdvent, setDisplayAdvent] = useState();

    useEffect(() => {
        if (isSuccessConfigApi) {
            if (configApi.displayAdvent) {
                setDisplayAdvent(configApi.displayAdvent);
            }
        }
    }, [configApi]);


    const backOfficePath = config_src.apiUrl + '/admin';

    const [searchVisible, setSearchVisible] = useState(false);
    const toggleSearchBar = () => {
        setSearchVisible((prev) => !prev);
    };

    return (
        <header
            className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${headerPositionClass ? headerPositionClass : ""}`} >

            <div
                ref={headerRef}
                className={` ${headerPaddingClass ? "headerPaddingClass" : ""} sticky-bar clearfix stick `}
            >
                <SalesBarComponent />

                {isAdmin &&
                    <div className={width > 991 ? 'admin-header' : 'admin-header d-none'}>
                        <AdminHeader storeName={getStoreName} backOfficePath={backOfficePath} />
                    </div>
                }

                {getDisplayCountryHeader &&
                    <div className="country-header" style={{ maxHeight: '200px' }}>
                        <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                            <CountryDropdown countryDropdownInfo={countryDropdownInfo} />
                            <TextDisplay />
                            {/* <CountryDisplay/> */}
                        </div>
                    </div>
                }

                <div className="banner-top-color" style={{ maxHeight: '200px' }}>
                    <div className={'container'}>
                        <div className={width > 991 ? 'row' : 'row row-h-mob'}>
                            <div className='col-xl-2 col-lg-2 col-md-4 col-4'
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* header logo */}
                                <Logo imageUrl={getLogoHeader} logoClass='logo' />
                            </div>
                            <div className='d-lg-none col-xl-8 col-lg-8 d-none d-xl-block'>
                                {/* Search Bar */}
                                <Appx location='dropdown' />
                            </div>
                            <div className='col-xl-2 col-lg-10 col-md-8 col-8 center-icons-inside' style={{
                                display: 'flex',
                                alignItems: '',
                                justifyContent: 'flex-end',
                                paddingLeft: 0,
                            }}>
                                {/* Icon group */}
                                <IconGroup toggleSearchBar={toggleSearchBar} />
                            </div>
                            <div className={`d-xl-none banner-top-color col-md-12 col-sm-12 col-12 ${width < 992 ? `search-mobile ${getDisplayCountryHeader ? 'search-mobile-country' : ''}` : ''}`}
                                style={{
                                    ...(width < 992 && {
                                        height: searchVisible ? '50px' : '0px',
                                        overflow: searchVisible ? 'auto' : 'hidden',
                                    })
                                }}>
                                {/* Search Bar */}
                                <Appx location='dropdown' />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='banner-bottom-color' style={{ maxHeight: '70px' }}>
                    <div className={'container'}>
                        <div className='row'>
                            <div className='col-xl-2 d-none d-xl-block pl-0 pr-0'
                                style={{ display: 'flex', alignItems: 'center' }}>
                                {/* header logo */}
                                <FullMenu />
                            </div>
                            <div className="col-xl-8  d-none d-xl-block">
                                {/* Nav menu */}
                                <NavMenu />
                            </div>
                            <div className="col-xl-2 d-none d-xl-block">

                                {/* Icon group */}
                                {displayAdvent == 1 && width > 1199 && location.pathname !== `${process.env.PUBLIC_URL}/advent-calendar` &&
                                    <div className='main-menu' style={{ height: "100%" }}>
                                        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                            <span style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                                                <Link to={{
                                                    pathname: `${process.env.PUBLIC_URL}/advent-calendar`
                                                }}>
                                                    <span className="christmas-button" style={{ minWidth: "92%" }}>{t('Advent Calendar')}</span>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile menu */}
                <MobileMenu />
            </div>
        </header >
    );
};

HeaderOne.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string
};

export default HeaderOne;
