import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";

const Breadcrumb = ({ finalItem = 'span', finalUrl = '' }) => {
  return (
    <div className="breadcrumb-area pt-10 pb-10 bg-gray-3 breadcrumb-mobile">
      <div className="container">
        <div className="breadcrumb-content text-center">
          <Breadcrumbs
            separator={<span>/</span>}
            item={Link}
            finalItem={finalItem}
            finalProps={finalItem !== 'a' ? { } : { href: finalUrl }}
            compare={(a,b)=>a.weight-b.weight}
            removeProps={finalItem !== 'a' ? { weight: true } : { weight: true, to: true }}
          />
        </div>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
    finalItem: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    finalUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

Breadcrumb.defaultProps = {
    finalItem: 'span',
    finalUrl: '',
};

export default Breadcrumb;
