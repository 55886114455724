import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import config_src from '../../config/config';
import { getTheliaToken } from '../../helpers/session';

const {locale} = config_src;

export const defaultConfig = {
	keepUnusedDataFor: 300, // 5 minutes
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true
};


export const fastApi = createApi({
		...defaultConfig,
    reducerPath: 'fastApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${config_src.fastApi}/api/`,
        prepareHeaders: headers => {
            headers.set('Access-Control-Allow-Origin', '*');
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json');
            headers.set('Authorization', getTheliaToken());
            return headers;
        },
    }),
	
    endpoints: builder => ({
        getModule: builder.query({
            query: (data) => {
                const params = new URLSearchParams();
                if (data.module_name) {
                    params.append('module_name', data.module_name);
                }
                if (data.module_config_id) {
                    params.append('module_config_id', data.module_config_id);
                }
                if (data.locale) {
                    params.append('locale', data.locale);
                }
                return {
                    url: `module_config_i18n/custom_attribute/?${params.toString()}`,
                };
            },
        }),
        getLocale: builder.query({
            keepUnusedDataFor: 10,
            query: () => 'locale_config/custom_attribute/',
            // retry: 3,
        }),
        getSystem: builder.query({
            query: (variableName) => ({ url: `system_config/custom_attribute/?variable_name=${variableName}` }),
            transformResponse: (response) => response.value,
        }),
        getSystemAll: builder.query({
            query: () => ({ url: `system_config/custom_attribute/` }),
            transformResponse: (response) => response.reduce((acc, cur) => {
                acc[cur.name] = cur.value;
                return acc;
            }, {}),
            // retry: 3,
        }),
        getContent: builder.query({
            query: (contentId) => ({ url: `content/custom_attribute/?content_id=${contentId}&locale=${locale}` }),
        }),
        getBreadcrumb: builder.query({
            // TODO Here you have to manage somehow the location of the product page
            query: (variableName) => ({ url: `category/?category_id=${variableName}&visible=1&locale=${locale}` }),
            transformResponse: (response) => response.reverse(),
            transformErrorResponse: (data) => data,
        }),
        getCategories: builder.query({
            query: () => ({ url: `category_count/custom_attribute/` }),
            transformResponse: (response) => response?.category_count,
	          // keepUnusedDataFor: 0,
            // retry: 3,
        }),
        getCategoriesHierarchy: builder.query({
            query: () => ({ url: `category_menu/custom_attribute/?locale=${locale}` }),
            transformResponse: (response) => response.response,
        }),
        getProductDeliveryDelay: builder.query({
            query: (productId) => ({ url: `product_delays/custom_attribute/${productId}` }),
            transformResponse: (response) => response,
        }),
        getFolderContent: builder.query({
            query: (folderId) => ({ url: `/content_folder?folder_id=${folderId}&locale=${locale}` }),
            transformResponse: (response) => response,
        }),
        getCategoryChildren: builder.query({
            query: (categoryId) => ({ url: `/category_children?category_id=${categoryId}` }),
            transformResponse: (response) => response,
        }),
        getTagManager: builder.query({
            query: () => ({ url: `google_tag_manager` }),
        }),
        getMultiplier: builder.query({
            query: ({ pse, attr_id }) => ({ url: `multiplier/?locale=${locale}&attribute_id=${attr_id}&pse_id=${pse}` }),
        }),
        getCombinations: builder.query({
            query: ({ product_id }) => ({ url: `products/combinations/${product_id}/${locale}` }),
            // 	http://localhost:8080/api/products/combinations/2354/de_DE,
            transformResponse: (response) => response.map((item) => {
                // Sort the combinations by attribute_id
                const combi = item.combinations;
                const sortedCombinations = combi.sort((a, b) => a.attribute_id - b.attribute_id);
                // Return the sorted combinations
                return {
                    ...item,
                    combinations: sortedCombinations
                };
            }),
        }),
        getMultipliers: builder.mutation({
            query: ({ body, attribute_id }) => ({
                url: `multiplier/bulk/?attribute_id=${attribute_id}&locale=${locale}`,
                method: 'POST',
                body,
            }),
        }),
        getProductComments: builder.query({
            query: (product_id) => ({ url: `comments/${product_id}` }),
        }),
        getProductFulfilmentCenter: builder.query({
            query: (pseId) => ({ url: `fulfilment_center/?search_pse=${pseId}` }),
            transformResponse: (response) => response.response,
        }),
        getOrderLocalPickup: builder.query({
            query: (orderId) => ({ url: `order_local_pickup/?search_order_id=${orderId}` }),
            transformResponse: (response) => response.response,
        }),
        getLocalPickup: builder.query({
            query: () => ({ url: `local_pickup/` }),
            transformResponse: (response) => response.data,
        }),
        getKeyWordsActive: builder.query({
            query: (groupCode) => ({
                url: `product-associated-keywords?group_code=${groupCode}&locale=${locale}`,
            }),
        }),
        getSelectionProduct: builder.query({
            query: (product_id) => ({ url: `selection_product/${product_id}/${locale}` }),
            transformResponse: (response) => response.data,
        }),
        getProductFulfilmentCenterStock: builder.query({
            query: (pseId) => ({ url: `fulfilment_center_stock/${pseId}` }),
            transformResponse: (response) => response.data,
        }),
        getAqurateRecommendationItem: builder.query({
            query: ({ pse_id, option }) => ({
                url: `recommendation/item-data/?options=${option}&product_id=${pse_id}`
            }),
        }),
        getAqurateRecommendationCategory: builder.query({
            query: ({ category_id }) => ({
                url: `recommendation/category-data/?category_id=${category_id}`
            }),
        }),
        getAqurateRecommendationBestseller: builder.query({
            query: () => ({
                url: 'recommendation/bestseller-data/'
            }),
        }),
        getRewritingUrl: builder.mutation({
            query: (data) => ({
                url: 'search_url/',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: data,
            }),
        }),
        getCategoryCountSingle: builder.query({
            query: ({category_id, brand_id}) => ({
                url: `category_count/single/${category_id}` + `${brand_id ? `?brand_id=${brand_id}` : ''}`
            }),
        }),
        getBrandList: builder.query({
            query: (categoryId) => ({ url: `brand_list?locale=${locale}` + `${categoryId ? `&category_id=${categoryId}` : ''}` }),
        }),
        getBrand: builder.query({
            query: (brandId) => ({ url: `brands/${brandId}/${locale}` }),
        }),
        getBrandCount: builder.query({
            query: (brand_id) => ({
                url: `brand_count/${brand_id}`
            }),
        }),
        getBrandCategoryCount: builder.query({
            query: ({brand_id, category_id}) => ({
                url: `brand_category_count/?brand_id=${brand_id}&category_id=${category_id}`
            }),
        }),
        getCommentRights: builder.query({
            query: ({pse_id, customer_id}) => ({
                url: `comments/rights/${pse_id}/${customer_id}`
            }),
        }),
        getCategoryChildrenCount: builder.query({
            query: (categoryIds) => ({
                url: `category_count/children/?category_ids=${categoryIds}` }),
        }),
    }),
});

export const {
    useGetLocaleQuery,
    useGetModuleQuery,
    useGetSystemQuery,
    useGetContentQuery,
    useGetBreadcrumbQuery,
    useGetCategoriesQuery,
    useGetMultiplierQuery,
    useGetCategoriesHierarchyQuery,
    useGetProductDeliveryDelayQuery,
    useGetFolderContentQuery,
    useGetCategoryChildrenQuery,
    useGetMultipliersMutation,
    useGetTagManagerQuery,
    useGetSystemAllQuery,
    useGetProductCommentsQuery,
    useGetProductFulfilmentCenterQuery,
    useGetOrderLocalPickupQuery,
    useGetLocalPickupQuery,
    useGetKeyWordsActiveQuery,
    useGetSelectionProductQuery,
    useGetProductFulfilmentCenterStockQuery,
    useGetAqurateRecommendationItemQuery,
    useGetAqurateRecommendationCategoryQuery,
    useGetAqurateRecommendationBestsellerQuery,
    useGetCombinationsQuery,
    useGetRewritingUrlMutation,
    useGetCategoryCountSingleQuery,
    useGetBrandListQuery,
    useGetBrandQuery,
    useGetBrandCountQuery,
    useGetBrandCategoryCountQuery,
    useGetCommentRightsQuery,
    useGetCategoryChildrenCountQuery,
} = fastApi;

