import React, { useEffect } from 'react';
import { createVBadge } from './shopvote-widget.js';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';

const VoteBadge = () => {
    const { getShopvoteShopId: myShopID } = useLocaleConfigSelectors();

    useEffect(() => {
        if (!myShopID) return;

        // const myBadgetType = 3;
        // const myLanguage = 'DE';
        // const mySrc = window.location.protocol === 'https:' ? 'https' : 'http';

        //const script = document.createElement('script');
        // script.src = './shopvote-widget.js';
        // script.onload = () => {
        //     try {
        //         createVBadge(myShopID, myBadgetType, mySrc);
        //     }
        //     catch (e) {
        //         console.error(e);
        //     }

        // };
        // window.addEventListener('error', function (event) {
        //     if (event.filename.endsWith('shopvote-widget.js') && event.message.includes('Unexpected token')) {
        //         event.preventDefault();
        //     }
        // });

        // document.body.appendChild(script);
        import('./shopvote-widget.js')
            .then(({ createVBadge }) => {
                const myBadgetType = 3;
                const myLanguage = 'DE';
                const mySrc = window.location.protocol === 'https:' ? 'https' : 'http';
                try {
                    createVBadge(myShopID, myBadgetType, mySrc);
                } catch (e) {
                    console.error(e);
                }
            })
            .catch(error => console.error('An error occurred while loading the script: ', error));

        // Cleanup function
        // return () => {
        //     document.body.removeChild(script);
        // };
    }, [myShopID]);

    return <div />;
};

export default VoteBadge;
